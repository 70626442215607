import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash-es/debounce'

export default class TypeaheadSearchController extends Controller {
  static targets = ['form', 'query', 'results', 'loadingSpinner']
  static values = {
    searchEndpoint: String
  }

  initialize () {
    this.conductSearch = debounce(this.conductSearch, 500)
    this.previousQuery = ''
  }

  connect () {
    window.addEventListener('clearTypeahead', this.clearResults.bind(this))
    document.addEventListener('click', this.handleClicks.bind(this), false)
  }

  get query () {
    return this.queryTarget.value
  }

  conductSearch () {
    this.showLoadingSpinner()
    this.previousQuery = this.query
    this.showSearchResults()
    this.resultsTarget.src = this.searchEndpointValue + `?query=${this.query}`
  }

  fetchResults () {
    if (this.query === '') {
      this.clearResults()
      return
    }

    if (this.query === this.previousQuery) {
      this.showSearchResults()
    } else {
      this.conductSearch()
    }
  }

  handleClicks (event) {
    const clickFromDropdown = [this.queryTarget, this.resultsTarget].includes(
      event.target
    )
    if (!clickFromDropdown) this.hideSearchResults()
  }

  clearResults () {
    this.queryTarget.value = ''
    this.resultsTarget.innerHTML = ''
    this.hideSearchResults()
    this.hideLoadingSpinner()
  }

  showSearchResults () {
    this.resultsTarget.classList.remove('hidden')
  }

  hideSearchResults () {
    this.resultsTarget.classList.add('hidden')
  }

  showLoadingSpinner () {
    this.loadingSpinnerTarget.classList.remove('hidden')
  }

  hideLoadingSpinner () {
    this.loadingSpinnerTarget.classList.add('hidden')
  }
}
